import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { SessionConfigService } from '../services/session-config.service';
import { AuthService } from '../services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/session.service";
import * as i3 from "../services/session-config.service";
import * as i4 from "../../../@rapi/w3/apps/auth/tokens";
/**
 * Captura slug ( codigo sessao ) da url e ativa configurações
 */
var CodeSessionByUrlGuard = /** @class */ (function () {
    function CodeSessionByUrlGuard(_router, _service, _config, _auth) {
        this._router = _router;
        this._service = _service;
        this._config = _config;
        this._auth = _auth;
    }
    CodeSessionByUrlGuard.prototype.canActivate = function (next, state) {
        var code = next.params['codigo'];
        if (!code) {
            return this._redirect();
        }
        return this.can(code);
    };
    CodeSessionByUrlGuard.prototype.canActivateChild = function (childRoute, state) {
        var code = childRoute.params['codigo'];
        if (!code) {
            return this._redirect();
        }
        return this.can(code);
    };
    CodeSessionByUrlGuard.prototype.can = function (code) {
        var _this = this;
        console.log('Guard CODE->', code);
        return this._service
            .search(code)
            .toPromise()
            .then(function (s) { return _this._activate(s); }, function () { return _this._redirect(); });
    };
    CodeSessionByUrlGuard.prototype._activate = function (session) {
        console.log('Guard ACTIVATE->', session);
        if (this._config.hasOtherSeason(session.code, session.id)) {
            this._config.dispatchClearData();
            return this._logout(session);
        }
        console.log('Guard ACTIVATE-> NOT OLD SEASON');
        this._config.activate(session);
        return Promise.resolve(true);
    };
    CodeSessionByUrlGuard.prototype._redirect = function () {
        this._router.navigateByUrl('/erro');
        return false;
    };
    CodeSessionByUrlGuard.prototype._logout = function (session) {
        var _this = this;
        console.log('Guard CODE-> LOGOUT', session.code);
        this._config.reset();
        return new Promise(function (resolve) {
            _this._auth
                .logout()
                .subscribe(function () {
                _this._router.navigateByUrl('/' + session.code + '/login');
                resolve(true);
            }, function () {
                _this._router.navigateByUrl('/' + session.code + '/login');
                resolve(true);
            });
        });
    };
    CodeSessionByUrlGuard.ngInjectableDef = i0.defineInjectable({ factory: function CodeSessionByUrlGuard_Factory() { return new CodeSessionByUrlGuard(i0.inject(i1.Router), i0.inject(i2.SessionService), i0.inject(i3.SessionConfigService), i0.inject(i4.W3_AUTH_SERVICE)); }, token: CodeSessionByUrlGuard, providedIn: "root" });
    return CodeSessionByUrlGuard;
}());
export { CodeSessionByUrlGuard };
