import {environmentBase, EnvironmentType} from './environment.base';

const confSocket = {
    host: null,
    port: null,
    tls: true,
    key: null,
};

let host = window.location.origin;
let debug = false;

if (host.includes('quase.online') || host.includes('.web.app')) {
    host = 'https://api.quase.online';
    confSocket.host = 'soketi.quase.online';
} else if (host.endsWith(':5185')) {
    host = host.replace(':5185', ':5180');
    confSocket.host = window.location.host.replace(':5185', '');
    confSocket.key = 'b3UlVE1nGy';
    confSocket.tls = false;
    confSocket.port = 5183;
    debug = true;
} else {
    const baseHostname = window.location.hostname;
    host = `https://api.${baseHostname}`;
    confSocket.host = `soketi.${baseHostname}`;
    confSocket.key = 'X0CVLREVzS';
}

export const environment: EnvironmentType = {
    ...environmentBase,
    production: true,
    hmr: false,
    DEBUG: debug,
    URL_API: `${host}/api/v1`,
    URL_APP: window.location.origin,
    SOCKET_APP_KEY: confSocket.key,
    SOCKET_URL_AUTH: `${host}/broadcasting/auth`,
    SOCKET_HOST: confSocket.host,
    SOCKET_PORT: confSocket.port,
    SOCKET_FORCE_TLS: confSocket.tls,
    UPLOAD_TOKEN: '',
    ENVIRONMENT: 'production',
};

console.log('Environment', environment); // TODO REMOVE