var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { environmentBase } from './environment.base';
var confSocket = {
    host: null,
    port: null,
    tls: true,
    key: null,
};
var host = window.location.origin;
var debug = false;
if (host.includes('quase.online') || host.includes('.web.app')) {
    host = 'https://api.quase.online';
    confSocket.host = 'soketi.quase.online';
}
else if (host.endsWith(':5185')) {
    host = host.replace(':5185', ':5180');
    confSocket.host = window.location.host.replace(':5185', '');
    confSocket.key = 'b3UlVE1nGy';
    confSocket.tls = false;
    confSocket.port = 5183;
    debug = true;
}
else {
    var baseHostname = window.location.hostname;
    host = "https://api." + baseHostname;
    confSocket.host = "soketi." + baseHostname;
    confSocket.key = 'X0CVLREVzS';
}
export var environment = __assign({}, environmentBase, { production: true, hmr: false, DEBUG: debug, URL_API: host + "/api/v1", URL_APP: window.location.origin, SOCKET_APP_KEY: confSocket.key, SOCKET_URL_AUTH: host + "/broadcasting/auth", SOCKET_HOST: confSocket.host, SOCKET_PORT: confSocket.port, SOCKET_FORCE_TLS: confSocket.tls, UPLOAD_TOKEN: '', ENVIRONMENT: 'production' });
console.log('Environment', environment); // TODO REMOVE
