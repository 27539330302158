import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ItemRespDec} from '@rapi/w3';
import {Slide} from '@sip/common/sdk-slide/model';
import {PanelQuestionModel, ResponseVoteModel} from '@sip/common/models';

import {SessionConfigService} from './session-config.service';
import {InputConfirmReply, QuestTypeEnum, Reply} from '../models/session.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VoteService {

    private _slide: Slide;
    private _text_response: string;
    private _p: string[];
    private _dictionary: Record<string, string>;
    private _start;
    private _question: PanelQuestionModel;
    private _votes = new Map<number, ResponseVoteModel>();
    private _confirmVote: boolean;

    constructor(
        private _config: SessionConfigService,
        private http: HttpClient) {
    }

    setSlide(slide: Slide): void {
        this._slide = slide;
        this.setDictionary(this._slide.getDictionary());
    }

    setQuestion(q: PanelQuestionModel): void {
        this._question = q;
        this.reset();

        this._p = this.getSelectChoices();
    }

    isTextOrNumerical(): boolean {
        return ['text', 'numerical'].includes(this._question.type);
    }

    isLongPoll(): boolean {
        return ['long_poll'].includes(this._question.type);
    }

    isChangedVote(alternative: string): boolean {
        const v = this.getCurrentVote();

        if (!v) {
            return true;
        } else if (this._question.type === 'text') {
            return v.text_response !== alternative;
        } else if (this._question.type === 'numerical') {
            return v.chosen_response.length > 0 && v.chosen_response[0] !== alternative;
        } else {
            return true; // sempre ira retornar true para POLL
        }
    }

    press(alternative: string): Promise<any> {
        if (!this._start) {
            return;
        }

        if (this._question.type === 'text') {
            return this.pressText(alternative);
        } else if (this._question.type === 'numerical') {
            return this.pressNumerical(alternative);
        } else {
            return this.pressPoll(alternative);
        }
    }

    pressFromKeyPad(responses: string[]): void {
        if (!this._start) {
            return;
        }

        switch (this._question.type) {
            case 'text':
            case 'numerical':
                this.press(responses[0]);
                break;

            case 'poll':
                this._p = responses;
                this.confirm(true);
        }
    }

    pressLongPoll(alternatives: string[]): Promise<any> {
        this._p = alternatives;
        return this.confirm();
    }

    private pressPoll(alternative: string): Promise<any> {
        const i = this._p.indexOf(alternative);

        if (i !== -1) {
            this._p.splice(i, 1);
        } else if (this._p.length === this._question.choices) {
            this._p.shift();
            this._p.push(alternative);
        } else {
            this._p.push(alternative);
        }

        if (this._slide) {
            this._slide.selectAlternative(this._p);
        }

        return this.confirm();
    }

    private pressText(response: string): Promise<any> {
        this._text_response = response;
        return this.confirm();
    }

    private pressNumerical(response: string): Promise<any> {
        this._p = [response];
        return this.confirm();
    }

    reset(): void {
        this._p = [];
        this._text_response = null;
    }

    startVoting(): void {
        // console.log('VoteService.startVoting');
        this._start = new Date;
    }

    started(): boolean {
        return !!this._start;
    }

    stopVoting(): void {
        // console.log('VoteService.stopVoting');
        this._start = null;
    }

    confirm(checkConfirm = true): Promise<any> {
        if (checkConfirm && this._confirmVote) {
            return Promise.resolve(null);
        }

        const data = {
            'question_id': this._question.questId,
            'start_vote': this._start.getTime(),
            'end_vote': (new Date).getTime(),
            'chosen_response': this._p,
            'text_response': this._text_response,
            'view': this._config.info.view
        };

        return new Promise((resolve, reject) => {

            this.http.post<ItemRespDec<ResponseVoteModel>>(`${environment.URL_API}/mod/participants/votes`, data)
                .subscribe(
                    result => {
                        this.setVotes([result.data]);
                        resolve();
                    },
                    () => {
                        this.errorOnVote();
                        reject();
                    }
                );

        });
    }

    private errorOnVote(): void {
        this._p = this.getSelectChoices();
        // console.log('VoteService.errorOnVote', this._p);

        if (this._slide) {
            this._slide.selectAlternative(this._p);
        }
    }

    getInput(): InputConfirmReply {
        const polls: Reply[] = this._p.map(label => ({
            empty: false,
            label: label,
            value: this._dictionary[label] ? this._dictionary[label] : ''
        }));

        let rest = this._question.choices - polls.length;

        while (rest > 0) {
            rest--;
            polls.push({empty: true, label: null, value: null});
        }

        return {
            inputType: this.getInputType(),
            reply_poll: polls,
            reply_text: this._text_response || this._p[0]
        };
    }

    getInputType(): QuestTypeEnum {
        if (this.isTextOrNumerical()) {
            return QuestTypeEnum.QUEST_TEXT;
        }

        if (this.isLongPoll()) {
            return QuestTypeEnum.QUEST_LONG_POLL;
        }

        return QuestTypeEnum.QUEST_POLL;
    }

    getCurrentVote(): ResponseVoteModel {
        return this._votes.get(this._question.questId);
    }

    setVotes(votes: ResponseVoteModel[]): void {
        votes.forEach(v => this._votes.set(v.question_id, v));
    }

    clear(questId: number): void {
        this.reset();
        this._votes.delete(questId);
    }

    clearAllVotes(): void {
        this.reset();
        this._votes.clear();
    }

    getSelectChoices(): string[] {
        if (!this._question) {
            return [];
        }

        const voted = this.getCurrentVote();
        return voted && voted.chosen_response ? [...voted.chosen_response] : [];
    }

    getSelectTextResponse(): string {
        if (!this._question) {
            return null;
        }

        const voted = this.getCurrentVote();

        if (this._question.type === 'numerical') {
            return voted && voted.chosen_response.length > 0 ? voted.chosen_response[0] : null;
        }

        return voted ? voted.text_response : null;
    }

    getSelectKeyPadTextResponse(): string[] {
        if (!this._question) {
            return null;
        }

        const voted = this.getCurrentVote();

        if (this._question.type === 'poll') {
            return this.getSelectChoices();
        } else if (this._question.type === 'numerical') {
            return voted && voted.chosen_response.length > 0 ? [voted.chosen_response[0]] : [];
        } else {
            return voted ? [voted.text_response] : [];
        }
    }

    requestMyVotes(): void {
        // console.log('VoteService.requestMyVotes');
        this.http.get<ItemRespDec<ResponseVoteModel[]>>(`${environment.URL_API}/mod/participants/votes`)
            .subscribe((result) => this.setVotes(result.data));
    }

    enableConfirmVote(): void {
        this._confirmVote = true;
    }

    setDictionary(dictionary: Record<string, string>): void {
        this._dictionary = dictionary;
    }

    hasVoted(): boolean {
        const v = this.getCurrentVote();
        if (!v) {
            return false;
        }

        if (v.text_response) {
            return true;
        }

        return v.chosen_response && v.chosen_response.length > 0;
    }

    getQuestion(): PanelQuestionModel | null {
        return this.asQuestion() ? {...this._question} : null;
    }

    asQuestion(): boolean {
        return this._question && this._question.exists;
    }
}