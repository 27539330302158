import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';

import {Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {CollectionResponse, Response20x, skipToast} from '@rapi/w3';
import {SimpleStore} from '@sip/common/services/simple-store.service';
import {SessionConfigService} from './session-config.service';
import {EventPayload, MessageRegistered} from '../events';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';



export interface ChatState {
    unread: number;
    messages: MessageRegistered[];
    lastMessageUnread: MessageRegistered;
}

@Injectable({
    providedIn: 'root'
})
export class ChatStoreService extends SimpleStore<ChatState> {

    constructor(
        private _config: SessionConfigService,
        private _http: HttpClient,
        private _router: Router,
        private _snackBar: MatSnackBar
    ) {
        super({
            lastMessageUnread: null,
            unread: 0,
            messages: []
        });

        this.listener();
        // this.debug('ChatState');
    }

    private listener(): void {
        this._load();

        this._config.broadCast$
            .pipe(filter((e: EventPayload): e is MessageRegistered => e instanceof MessageRegistered))
            .subscribe((e: MessageRegistered) => this._handleAction(e));
    }

    private _load(): void {
        const options = {
            params: {
                take: '-1',
                sort: 'created_at'
            }
        };

        this._http.get(`${environment.URL_API}/mod/participants/messages`, options)
            .subscribe((response: CollectionResponse) => {
                const messages = response.data.map(d => new MessageRegistered(d, 'new_message'));
                this._setMessages(messages);
            });
    }

    private _handleAction(e: MessageRegistered): void {
        console.log('_handleAction', e);

        switch (e.action) {
            case 'new_message':
                this._setNewMsg(e);
                break;

            case 'as_deleted':
                this._setMessages(this.state.messages.filter(m => m.id !== e.id));
                break;

            case 'as_read':
                // no actions, but unload
                break;

            default:
                this._load();
                break;
        }
    }

    private _setNewMsg(newMsg: MessageRegistered): void {
        const msgs = [...this.state.messages, newMsg];

        if (newMsg.isSpeaker) {
            this.setState({
                ...this.state,
                lastMessageUnread: newMsg,
                messages: msgs,
                unread: this.state.unread + 1
            });
        } else {
            this.setState({
                ...this.state,
                lastMessageUnread: null,
                messages: msgs,
                unread: 0
            });
        }

    }

    private _setMessages(messages: MessageRegistered[]): void {
        this.setState({
            ...this.state,
            messages: [...messages]
        });
    }

    public loadInit(): void {
        this._load();
    }

    onChangeMessages(): Observable<MessageRegistered[]> {
        return this.state$
            .pipe(
                map(s => s.messages),
                distinctUntilChanged()
            );
    }

    onNewMessage(): Observable<MessageRegistered> {
        return this.state$
            .pipe(
                map(s => s.lastMessageUnread),
                filter(lstMsg => !!lstMsg),
                distinctUntilChanged()
            );
    }

    onChangeUnread(): Observable<number> {
        return this.state$
            .pipe(
                map(s => s.unread),
                distinctUntilChanged()
            );
    }

    sendMessage(data): Observable<any> {
        return this._http.post<Response20x>(`${environment.URL_API}/mod/participants/messages`, data, skipToast)
            .pipe(
                map(result => result.data)
                // tap(m => this._setNewMsg(m))
            );
    }

    setRead(): void {
        this.setState({
            ...this.state,
            unread: 0
        });
    }

    public showSnackNewMessage(): void {
        const message = this.state.lastMessageUnread
            ? `Você recebeu uma nova resposta: ${this.state.lastMessageUnread.message}`
            : `Você recebeu uma nova resposta!`;

        this._snackBar
            .open(message, 'Ver', {
                duration: 3000,
            })
            .onAction()
            .subscribe(() => this._router.navigateByUrl('/sessao/perguntar'));
    }
}
