import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ItemResponse, Response20x} from '@rapi/w3';
import {W3StorageService} from '@rapi/w3/apps/storage';
import {W3AuthAbstractService, W3MeService} from '@rapi/w3/apps/auth';

import {Observable} from 'rxjs/Observable';
import {map, shareReplay, tap} from 'rxjs/operators';

import * as moment from 'moment';

import {SessionConfigService} from './session-config.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthService extends W3AuthAbstractService {

    constructor(
        private config: SessionConfigService,
        http: HttpClient, storage: W3StorageService, me: W3MeService) {
        super(http, storage, me);
    }

    /**
     * @param {string} login
     * @param {string} password
     * @param {string} code session code
     * @returns {Observable<string>}  status [error, success]
     */
    login(login: string, password: string, code: string): Observable<string> {
        const data = {login, password, code, token: this.getDeviceToken()};

        return this.http
            .post(`${environment.URL_API}/mod/participants/auth/login`, data)
            .pipe(
                tap(res => this.setSession(res)),
                map((r: Response20x) => r.status),
                shareReplay() // mytodo verificar se o shareReplay pode ser usado MAP junto
            );
    }

    loginPublic(name: string, code: string): Observable<string> {
        const data = {name, code, token: this.getDeviceToken()};

        return this.http
            .post(`${environment.URL_API}/mod/participants/auth/sign-up-public`, data)
            .pipe(
                tap(res => this.setSession(res)),
                map((r: Response20x) => r.status),
                shareReplay() // mytodo verificar se o shareReplay pode ser usado MAP junto
            );
    }

    register(data): Observable<string> {
        data.token = this.getDeviceToken();

        return this.http
            .post(`${environment.URL_API}/mod/participants/auth/sign-up-private`, data)
            .pipe(
                tap(res => this.setSession(res)),
                map((r: Response20x) => r.status),
                shareReplay() // mytodo verificar se o shareReplay pode ser usado MAP junto
            );
    }

    logout(): Observable<string> {
        const refreshToken: string = this.storage.get('access_token');
        const options = {
            headers: {Authorization: `Bearer ${refreshToken}`},
        };

        this.storage.remove('access_token');
        this.storage.remove('expires_at');
        this.config.dispatchClearData();

        return this.http
            .post(`${environment.URL_API}/mod/participants/auth/logout`, null, options)
            .pipe(
                map((r: Response20x) => r.status)
            );
    }

    public forceLogout(): void {
        console.log('forceLogout');
        this.clearToken();
        window.location.href = '/app';
    }

    protected setSession(authResult): void {
        console.log('setSession', authResult);
        const expiresAt = moment().add(authResult.data.expires_in, 'second');

        this.storage.set('access_token', authResult.data.access_token);
        this.storage.set('expires_at', JSON.stringify(expiresAt.valueOf()));
    }

    getUrlRefreshToken(): string {
        return `${environment.URL_API}/mod/participants/auth/refresh`;
    }

    setDeviceToken(token: string): void {
        window.localStorage.setItem('device_token', token);
    }

    getDeviceToken(): string {
        let token = window.localStorage.getItem('device_token');
        token = token ? token : this.uuid();

        this.setDeviceToken(token);
        return token;
    }

    private uuid(): string {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    requestSessionConfigOptIn(code: string): Observable<any>  {
       return this.http
            .post<ItemResponse>(`${environment.URL_API}/mod/participants/sessions/config/theme`, {code})
            .pipe(map(res => res.data.opt_in));
    }
}