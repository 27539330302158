import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SimpleStore} from '@sip/common/services/simple-store.service';
import {CollectionResponse} from '@rapi/w3';

import {FuseNavigation} from '@fuse/types';

import {map} from 'rxjs/operators';

import {Post, PostFromEnum, PostState} from '../models/post.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PostService extends SimpleStore<PostState> {

    constructor(public http: HttpClient) {
        super({
            posts: [],
            currentSlug: '',
            from: PostFromEnum.UNLOAD
        });
    }

    loadPagesGuest(sessionCode): void {
        this.http
            .post<CollectionResponse>(`${environment.URL_API}/mod/participants/sessions/pages/guest`, {'code': sessionCode})
            .pipe(map(res => res.data))
            .subscribe(posts => this.setPosts(posts, PostFromEnum.GUEST));
    }

    loadPagesAuth(sessionCode): void {
        this.http
            .post<CollectionResponse>(`${environment.URL_API}/mod/participants/sessions/pages/auth`, {'code': sessionCode})
            .pipe(map(res => res.data))
            .subscribe(posts => this.setPosts(posts, PostFromEnum.AUTH));
    }

    getCurrentSlug(): string {
        return this.state.currentSlug;
    }

    getCurrentPost(): Post {
        console.log('getCurrentPost', this.state);
        return this.state.posts.find(post => post.slug === this.getCurrentSlug());
    }

    setCurrentSlug(slug: string): void {
        console.log('set slug', slug, this.state);
        this.setState({
            ...this.state,
            currentSlug: slug
        });
    }

    setPosts(posts: Post[], from: PostFromEnum): void {
        console.log('set posts', from, posts);
        this.setState({
            ...this.state,
            posts: posts,
            from: from
        });
    }

    getNavigations(urlStart = ''): FuseNavigation[] {
        return this.state.posts.map<FuseNavigation>(p => ({
            id: `post-${p.id}`,
            title: p.title,
            type: 'item',
            icon: 'account_box',
            url: `${urlStart}/pagina/${p.slug}`
        }));
    }
}
