var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { map, shareReplay, tap } from 'rxjs/operators';
import { W3AuthAbstractService } from './auth-abstract.service';
import { W3StorageService } from '../storage';
import { environment } from '../../../../environments/environment';
import { W3MeService } from "./me.service";
var W3AuthService = /** @class */ (function (_super) {
    __extends(W3AuthService, _super);
    function W3AuthService(http, storage, me) {
        return _super.call(this, http, storage, me) || this;
    }
    W3AuthService.prototype.getUrlRefreshToken = function () {
        return environment.URL_API + "/rapi/guardian/auth/refresh";
    };
    W3AuthService.prototype.login = function (email, password) {
        var _this = this;
        return this.http
            .post(environment.URL_API + "/rapi/guardian/auth/login", { email: email, password: password })
            .pipe(tap(function (res) { return _this.setSession(res); }), 
        // map(() => {
        //     return {id: 1, name: 'admin'};
        // }),
        shareReplay() // mytodo verificar se o shareReplay pode ser usado MAP junto
        );
    };
    W3AuthService.prototype.remind = function (data) {
        return this.http
            .post(environment.URL_API + "/rapi/guardian/auth/password/remind", data)
            .pipe(map(function (resp) {
            console.log('resp', resp);
            return resp;
        }));
    };
    W3AuthService.prototype.reset = function (data) {
        return this.http
            .post(environment.URL_API + "/rapi/guardian/auth/password/reset", data)
            .pipe(map(function (resp) {
            console.log('resp', resp);
            return resp;
        }));
    };
    return W3AuthService;
}(W3AuthAbstractService));
export { W3AuthService };
