import {FuseNavigation} from '@fuse/types';

export const navigationAuth: FuseNavigation[] = [
    {
        id: 'app',
        title: 'Apresentação',
        type: 'item',
        icon: 'crop_landscape',
        url: '/sessao/view'
    },
    {
        id: 'views',
        title: 'Alternar visualização',
        type: 'item',
        icon: 'crop_landscape',
        url: '/sessao/selecionar-visualizacao'
    },
    {
        id: 'perguntar',
        title: 'Perguntar',
        type: 'item',
        icon: 'comment',
        url: '/sessao/perguntar',
    },
    {
        id: 'infos',
        title: 'Informações do participante',
        type: 'item',
        icon: 'account_box',
        url: '/sessao/perfil'
    },
    {
        id: 'password',
        title: 'Alterar senha',
        type: 'item',
        icon: 'vpn_key',
        url: '/sessao/redefinir-senha'
    },
    {
        id: 'sair',
        title: 'Sair',
        type: 'item',
        icon: 'exit_to_app',
        url: '/auth/sair'
    }
];
