import {Inject, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';

import {SessionModel} from '@sip/common/models';

import {Observable} from 'rxjs';
import {SessionService} from '../services/session.service';
import {SessionConfigService} from '../services/session-config.service';
import {W3_AUTH_SERVICE} from '../../../@rapi/w3/apps/auth';
import {AuthService} from '../services/auth.service';

/**
 * Captura slug ( codigo sessao ) da url e ativa configurações
 */
@Injectable({
    providedIn: 'root'
})
export class CodeSessionByUrlGuard implements CanActivate, CanActivateChild {

    constructor(
        private _router: Router,
        private _service: SessionService,
        private _config: SessionConfigService,
        @Inject(W3_AUTH_SERVICE) private _auth: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const code = next.params['codigo'];

        if (!code) {
            return this._redirect();
        }

        return this.can(code);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const code = childRoute.params['codigo'];

        if (!code) {
            return this._redirect();
        }

        return this.can(code);
    }

    private can(code: string): Promise<boolean> {
        console.log('Guard CODE->', code);

        return this._service
            .search(code)
            .toPromise()
            .then((s) => this._activate(s), () => this._redirect());
    }

    private _activate(session: SessionModel): Promise<boolean> {
        console.log('Guard ACTIVATE->', session);

        if (this._config.hasOtherSeason(session.code, session.id)) {
            this._config.dispatchClearData();
            return this._logout(session);
        }

        console.log('Guard ACTIVATE-> NOT OLD SEASON');
        this._config.activate(session);
        return Promise.resolve(true);
    }

    private _redirect(): boolean {
        this._router.navigateByUrl('/erro');
        return false;
    }

    private _logout(session: SessionModel): Promise<boolean> {
        console.log('Guard CODE-> LOGOUT', session.code);
        this._config.reset();

        return new Promise<boolean>((resolve) => {
            this._auth
                .logout()
                .subscribe(
                    () => {
                        this._router.navigateByUrl('/' + session.code + '/login');
                        resolve(true);
                    },
                    () => {
                        this._router.navigateByUrl('/' + session.code + '/login');
                        resolve(true);
                    }
                );
        });

    }

}
