import {Injectable} from '@angular/core';
import {ParticipantModel} from '../models/partipant.model';
import {BehaviorSubject, Observable} from 'rxjs';

import {W3StorageService} from '@rapi/w3/apps/storage';
import {GraphChangedData, SessionModel, SessionPanel, UserSocketModel} from '@sip/common/models';
import {SipSocketService, SwInChannel} from '@sip/common/web-socket/sip-socket.service';

import {AlertAllParticipants, EventPayload, MessageRegistered, ProjectChangedConfig} from '../events';
import {ClearDataEvent} from './events';

@Injectable({
    providedIn: 'root'
})
export class SessionConfigService {

    public channel: SwInChannel<UserSocketModel>;
    public info = {
        view: null
    };
    public participant$: Observable<ParticipantModel>;
    public session$: Observable<SessionModel>;
    public broadCast$: Observable<EventPayload>;

    private participantSubject = new BehaviorSubject<ParticipantModel>(null);
    private sessionSubject = new BehaviorSubject<SessionModel>(null);
    private broadCastSubject = new BehaviorSubject<EventPayload>(null);

    // private _config: ConfigSessionModel = {
    //
    //     ///
    //     type: 'privada', // privada publica restrita
    //     enable_forward: true,
    //     apresentation_type: 'keypad', // keypag slide
    //     enable_change_view: false,
    //     enable_countdown: true,
    //     has_feedback: true
    // };

    constructor(private socket: SipSocketService, private storage: W3StorageService) {
        this.participant$ = this.participantSubject.asObservable();
        this.session$ = this.sessionSubject.asObservable();
        this.broadCast$ = this.broadCastSubject.asObservable();
    }

    current(): SessionModel {
        return this.sessionSubject.getValue();
    }

    participant(): ParticipantModel {
        return this.participantSubject.getValue();
    }

    private disableSocket(): void {
        this.channel = null;
        this.socket.reset();
    }

    private configureSocket(): void {

        this.disableSocket();

        const sessionId = this.current().id;
        const participantId = this.participant().id;
        const nameChSession = `vis.session.${sessionId}`;
        const nameChParticipant = `vis.participant.${participantId}`;

        console.log('Connect Channels', nameChSession, nameChParticipant);

        this.socket.connect();
        this.channel = this.socket.joinChannel<UserSocketModel>(nameChSession);

        this.channel
            .listen('AlertAllParticipants', (e) => {
                console.log('AlertAllParticipants', e);
                this.broadCastSubject.next(new AlertAllParticipants(e.payload));
            });

        this.channel
            .listen('ProjectChangedConfig', (e) => {
                console.log('ProjectChangedConfig', e);
                this.broadCastSubject.next(new ProjectChangedConfig(e.payload));
            });

        this.channel
            .listen('SessionPanel', (e) => {
                console.log('SessionPanel', e);
                this.broadCastSubject.next(new SessionPanel(e));
            });

        this.channel
            .listen('GraphChangedData', (e) => {
                console.log('GraphChangedData', e);
                this.broadCastSubject.next(new GraphChangedData(e));
            });

        this.socket
            .createPrivateChannel(nameChParticipant)
            .listen('MessageRegistered', (e) => {
                console.log('MessageRegistered', e, e.data.action);
                this.broadCastSubject.next(new MessageRegistered(e.data.message, e.data.action));
            });
    }

    dispatchClearData(): void {
        this.broadCastSubject.next(new ClearDataEvent());
    }

    hasOtherSeason(code: string, id?: number): boolean {
        const currentId = +this.storage.get('session_id');
        const currentCode = this.storage.get('session_code');
        const checkId = +id;

        if (currentId > 0 && checkId > 0 && currentId !== checkId) {
            return true;
        }

        return currentCode && currentCode !== code;
    }

    activate(session: SessionModel): void {
        this.storage.set('session_id', session.id);
        this.storage.set('session_code', session.code);
        this.sessionSubject.next(session);
    }

    setParticipant(participant: ParticipantModel): void {
        if (participant) {
            this.checkSessionZoom();
        }

        this.participantSubject.next(participant);
        this.configureSocket();
    }

    isPrivate(): boolean {
        return this.identify === 'private';
    }

    isPublic(): boolean {
        return this.identify === 'public';
    }

    isAnonymous(): boolean {
        return this.identify === 'anonymous';
    }

    isRestrict(): boolean {
        return this.identify === 'restricted';
    }

    get identify(): string {
        return this.current() ? this.current().identify : 'restricted';
    }

    get type(): string {
        return this.current() ? this.current().type : 'poll';
    }

    get isPoll(): boolean {
        return this.type === 'poll';
    }

    get code(): string {
        return this.current() ? this.current().code : '';
    }

    get name(): string {
        return this.current() ? this.current().name : '';
    }

    get msgOrg(): string {
        if (this.current() && this.current().metas) {
            return this.current().metas.msg_inicio;
        }
    }

    get msgWait(): string {
        if (this.current() && this.current().metas) {
            return this.current().metas.msg_aguarde;
        }
    }

    get isOpen(): boolean {
        return this.current() ? this.current().status : false;
    }

    get typeView(): string {
        return this.current() ? this.current().view : null;
    }

    openSession(): void {
        const session = this.current();
        session.status = true;
        this.activate(session);
    }

    // se possui um USER ATIVO
    check(): boolean {
        return !!this.participant();
    }

    // Convidado ( não logado )
    guest(): boolean {
        return !this.check();
    }

    reset(): void {
        this.disableSocket();

        this.participantSubject.next(null);
        // this.participantSubject.complete();

        this.sessionSubject.next(null);
        // this.sessionSubject.complete();

        this.broadCastSubject.next(null);
        // this.broadCastSubject.complete();

        this.storage.remove('session_id');
        this.storage.remove('session_code');
    }

    private checkSessionZoom(): void {
        const session = this.current();

        // check is session with zoom AND Check if a Page Is Not in an iFrame
        if (session.zoom_enable && window.location === window.parent.location) {
            window.location.replace(`${window.location.origin}/zoom/`);
        }
    }

}
