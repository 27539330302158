import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from '../services/session.service';
import {SessionConfigService} from '../services/session-config.service';
import {ParticipantService} from '../services/participant.service';
import {ParticipantModel} from '../models/partipant.model';

/**
 * Verifica se Participant esta logado e carrega a sessão
 */
@Injectable({
    providedIn: 'root'
})
export class ParticipantSessionGuard implements CanActivate, CanActivateChild {

    constructor(private _participantService: ParticipantService,
                private _router: Router,
                private _sessionService: SessionService,
                private _config: SessionConfigService) {

    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

        console.log('Guard Load ME');

        if (this._config.check()) {
            return true;
        }

        return this._participantService
            .me('session.event')
            .toPromise()
            .then((s) => this._activate(s), () => this._redirect());

    }

    /**
     * CanActivateChild handler
     */
    public canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    private _activate(participant: ParticipantModel): boolean {
        console.log('Guard ACTIVATE->', participant);
        this._config.activate(participant.session);
        this._config.setParticipant(participant);
        if(participant.force_update_pwd){
            this._router.navigateByUrl('/sessao/redefinir-senha');
        }
        return true;
    }

    private _redirect(): boolean {
        this._router.navigateByUrl('/erro');
        return false;
    }

}
