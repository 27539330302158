var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { SimpleStore } from '@sip/common/services/simple-store.service';
import { map } from 'rxjs/operators';
import { PostFromEnum } from '../models/post.model';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PostService = /** @class */ (function (_super) {
    __extends(PostService, _super);
    function PostService(http) {
        var _this = _super.call(this, {
            posts: [],
            currentSlug: '',
            from: PostFromEnum.UNLOAD
        }) || this;
        _this.http = http;
        return _this;
    }
    PostService.prototype.loadPagesGuest = function (sessionCode) {
        var _this = this;
        this.http
            .post(environment.URL_API + "/mod/participants/sessions/pages/guest", { 'code': sessionCode })
            .pipe(map(function (res) { return res.data; }))
            .subscribe(function (posts) { return _this.setPosts(posts, PostFromEnum.GUEST); });
    };
    PostService.prototype.loadPagesAuth = function (sessionCode) {
        var _this = this;
        this.http
            .post(environment.URL_API + "/mod/participants/sessions/pages/auth", { 'code': sessionCode })
            .pipe(map(function (res) { return res.data; }))
            .subscribe(function (posts) { return _this.setPosts(posts, PostFromEnum.AUTH); });
    };
    PostService.prototype.getCurrentSlug = function () {
        return this.state.currentSlug;
    };
    PostService.prototype.getCurrentPost = function () {
        var _this = this;
        console.log('getCurrentPost', this.state);
        return this.state.posts.find(function (post) { return post.slug === _this.getCurrentSlug(); });
    };
    PostService.prototype.setCurrentSlug = function (slug) {
        console.log('set slug', slug, this.state);
        this.setState(__assign({}, this.state, { currentSlug: slug }));
    };
    PostService.prototype.setPosts = function (posts, from) {
        console.log('set posts', from, posts);
        this.setState(__assign({}, this.state, { posts: posts, from: from }));
    };
    PostService.prototype.getNavigations = function (urlStart) {
        if (urlStart === void 0) { urlStart = ''; }
        return this.state.posts.map(function (p) { return ({
            id: "post-" + p.id,
            title: p.title,
            type: 'item',
            icon: 'account_box',
            url: urlStart + "/pagina/" + p.slug
        }); });
    };
    PostService.ngInjectableDef = i0.defineInjectable({ factory: function PostService_Factory() { return new PostService(i0.inject(i1.HttpClient)); }, token: PostService, providedIn: "root" });
    return PostService;
}(SimpleStore));
export { PostService };
