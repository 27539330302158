import { BehaviorSubject } from 'rxjs';
import { W3StorageService } from '@rapi/w3/apps/storage';
import { GraphChangedData, SessionPanel } from '@sip/common/models';
import { SipSocketService } from '@sip/common/web-socket/sip-socket.service';
import { AlertAllParticipants, MessageRegistered, ProjectChangedConfig } from '../events';
import { ClearDataEvent } from './events';
import * as i0 from "@angular/core";
import * as i1 from "../../../@sip/common/web-socket/sip-socket.service";
import * as i2 from "../../../@rapi/w3/apps/storage/storage.service";
var SessionConfigService = /** @class */ (function () {
    // private _config: ConfigSessionModel = {
    //
    //     ///
    //     type: 'privada', // privada publica restrita
    //     enable_forward: true,
    //     apresentation_type: 'keypad', // keypag slide
    //     enable_change_view: false,
    //     enable_countdown: true,
    //     has_feedback: true
    // };
    function SessionConfigService(socket, storage) {
        this.socket = socket;
        this.storage = storage;
        this.info = {
            view: null
        };
        this.participantSubject = new BehaviorSubject(null);
        this.sessionSubject = new BehaviorSubject(null);
        this.broadCastSubject = new BehaviorSubject(null);
        this.participant$ = this.participantSubject.asObservable();
        this.session$ = this.sessionSubject.asObservable();
        this.broadCast$ = this.broadCastSubject.asObservable();
    }
    SessionConfigService.prototype.current = function () {
        return this.sessionSubject.getValue();
    };
    SessionConfigService.prototype.participant = function () {
        return this.participantSubject.getValue();
    };
    SessionConfigService.prototype.disableSocket = function () {
        this.channel = null;
        this.socket.reset();
    };
    SessionConfigService.prototype.configureSocket = function () {
        var _this = this;
        this.disableSocket();
        var sessionId = this.current().id;
        var participantId = this.participant().id;
        var nameChSession = "vis.session." + sessionId;
        var nameChParticipant = "vis.participant." + participantId;
        console.log('Connect Channels', nameChSession, nameChParticipant);
        this.socket.connect();
        this.channel = this.socket.joinChannel(nameChSession);
        this.channel
            .listen('AlertAllParticipants', function (e) {
            console.log('AlertAllParticipants', e);
            _this.broadCastSubject.next(new AlertAllParticipants(e.payload));
        });
        this.channel
            .listen('ProjectChangedConfig', function (e) {
            console.log('ProjectChangedConfig', e);
            _this.broadCastSubject.next(new ProjectChangedConfig(e.payload));
        });
        this.channel
            .listen('SessionPanel', function (e) {
            console.log('SessionPanel', e);
            _this.broadCastSubject.next(new SessionPanel(e));
        });
        this.channel
            .listen('GraphChangedData', function (e) {
            console.log('GraphChangedData', e);
            _this.broadCastSubject.next(new GraphChangedData(e));
        });
        this.socket
            .createPrivateChannel(nameChParticipant)
            .listen('MessageRegistered', function (e) {
            console.log('MessageRegistered', e, e.data.action);
            _this.broadCastSubject.next(new MessageRegistered(e.data.message, e.data.action));
        });
    };
    SessionConfigService.prototype.dispatchClearData = function () {
        this.broadCastSubject.next(new ClearDataEvent());
    };
    SessionConfigService.prototype.hasOtherSeason = function (code, id) {
        var currentId = +this.storage.get('session_id');
        var currentCode = this.storage.get('session_code');
        var checkId = +id;
        if (currentId > 0 && checkId > 0 && currentId !== checkId) {
            return true;
        }
        return currentCode && currentCode !== code;
    };
    SessionConfigService.prototype.activate = function (session) {
        this.storage.set('session_id', session.id);
        this.storage.set('session_code', session.code);
        this.sessionSubject.next(session);
    };
    SessionConfigService.prototype.setParticipant = function (participant) {
        if (participant) {
            this.checkSessionZoom();
        }
        this.participantSubject.next(participant);
        this.configureSocket();
    };
    SessionConfigService.prototype.isPrivate = function () {
        return this.identify === 'private';
    };
    SessionConfigService.prototype.isPublic = function () {
        return this.identify === 'public';
    };
    SessionConfigService.prototype.isAnonymous = function () {
        return this.identify === 'anonymous';
    };
    SessionConfigService.prototype.isRestrict = function () {
        return this.identify === 'restricted';
    };
    Object.defineProperty(SessionConfigService.prototype, "identify", {
        get: function () {
            return this.current() ? this.current().identify : 'restricted';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "type", {
        get: function () {
            return this.current() ? this.current().type : 'poll';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "isPoll", {
        get: function () {
            return this.type === 'poll';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "code", {
        get: function () {
            return this.current() ? this.current().code : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "name", {
        get: function () {
            return this.current() ? this.current().name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "msgOrg", {
        get: function () {
            if (this.current() && this.current().metas) {
                return this.current().metas.msg_inicio;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "msgWait", {
        get: function () {
            if (this.current() && this.current().metas) {
                return this.current().metas.msg_aguarde;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "isOpen", {
        get: function () {
            return this.current() ? this.current().status : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionConfigService.prototype, "typeView", {
        get: function () {
            return this.current() ? this.current().view : null;
        },
        enumerable: true,
        configurable: true
    });
    SessionConfigService.prototype.openSession = function () {
        var session = this.current();
        session.status = true;
        this.activate(session);
    };
    // se possui um USER ATIVO
    SessionConfigService.prototype.check = function () {
        return !!this.participant();
    };
    // Convidado ( não logado )
    SessionConfigService.prototype.guest = function () {
        return !this.check();
    };
    SessionConfigService.prototype.reset = function () {
        this.disableSocket();
        this.participantSubject.next(null);
        // this.participantSubject.complete();
        this.sessionSubject.next(null);
        // this.sessionSubject.complete();
        this.broadCastSubject.next(null);
        // this.broadCastSubject.complete();
        this.storage.remove('session_id');
        this.storage.remove('session_code');
    };
    SessionConfigService.prototype.checkSessionZoom = function () {
        var session = this.current();
        // check is session with zoom AND Check if a Page Is Not in an iFrame
        if (session.zoom_enable && window.location === window.parent.location) {
            window.location.replace(window.location.origin + "/zoom/");
        }
    };
    SessionConfigService.ngInjectableDef = i0.defineInjectable({ factory: function SessionConfigService_Factory() { return new SessionConfigService(i0.inject(i1.SipSocketService), i0.inject(i2.W3StorageService)); }, token: SessionConfigService, providedIn: "root" });
    return SessionConfigService;
}());
export { SessionConfigService };
