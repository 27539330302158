import { HttpClient } from '@angular/common/http';
import { W3StorageService } from '@rapi/w3/apps/storage';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../@rapi/w3/apps/storage/storage.service";
var SessionPrefetchService = /** @class */ (function () {
    function SessionPrefetchService(http, storage) {
        this.http = http;
        this.storage = storage;
    }
    SessionPrefetchService.prototype.getPrefetchAssets = function (code, mode) {
        var input = { code: code, mode: mode };
        return this.http
            .post(environment.URL_API + "/p/sessions/prefetch-assets", input)
            .pipe(map(function (r) { return r.data; }));
    };
    SessionPrefetchService.prototype.loadManual = function (sessionCode, mode) {
        var $body = document.getElementsByTagName('body')[0];
        var $el = document.createElement('div');
        $el.style.display = 'none';
        var addImg = function (url) {
            console.log('FETCH: MANUAL', url);
            var img = new Image();
            img.src = url;
            $el.appendChild(img);
        };
        this.getPrefetchAssets(sessionCode, mode).subscribe(function (data) {
            data.assets
                .filter(function (url) { return !url.endsWith('mp4'); })
                .forEach(function (url) { return addImg(url); });
            $body.appendChild($el);
        });
    };
    SessionPrefetchService.prototype.startPrefetchAssets = function (sessionCode, mode) {
        var _this = this;
        if (!('caches' in window)) {
            console.log('FETCH: BROWSER NAO SUPORTA CACHE');
            this.loadManual(sessionCode, mode);
            return;
        }
        var fetchAndCache = function (fileUrl, cache) {
            // Check first if video is in the cache.
            return cache.match(fileUrl)
                .then(function (cacheResponse) {
                // Let's return cached response if video is already in the cache.
                if (cacheResponse) {
                    console.log('FETCH: IS CACHED', fileUrl);
                    return cacheResponse;
                }
                console.log('FETCH: request init', fileUrl);
                // Otherwise, fetch the video from the network.
                return fetch(fileUrl, { mode: 'no-cors' })
                    .then(function (networkResponse) { return networkResponse.arrayBuffer(); })
                    .then(function (data) {
                    var response = new Response(data);
                    // Add the response to the cache and return network response in parallel.
                    console.log('FETCH: request finish', fileUrl);
                    cache.put(fileUrl, response.clone());
                    return response;
                    // Add the response to the cache and return network response in parallel.
                    // cache.put(fileUrl, networkResponse.clone());
                    // console.log('FETCH: request finish', fileUrl);
                    // return networkResponse;
                }, function (error) {
                    console.log('FETCH: request error', error);
                });
            });
        };
        this.getPrefetchAssets(sessionCode, mode).subscribe(function (data) {
            var assetUrl = data.assets;
            console.log('FETCH: data', data);
            _this.storage.set('CACHE', data.version);
            // Let's create a video pre-cache and store all first segments of videos inside.
            window.caches.open("VIS_CACHE_SESSION_" + sessionCode)
                .then(function (cache) { return Promise.all(assetUrl.map(function (fileUrl) { return fetchAndCache(fileUrl, cache); })); });
        });
    };
    SessionPrefetchService.ngInjectableDef = i0.defineInjectable({ factory: function SessionPrefetchService_Factory() { return new SessionPrefetchService(i0.inject(i1.HttpClient), i0.inject(i2.W3StorageService)); }, token: SessionPrefetchService, providedIn: "root" });
    return SessionPrefetchService;
}());
export { SessionPrefetchService };
